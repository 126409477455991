import * as React from "react";
import { styled, useStyletron } from "baseui";
import { Cell, Grid } from "baseui/layout-grid";
import { ParagraphSmall } from "baseui/typography";
import { StyledLink } from "baseui/link";
import Link from "next/link"; 

const StyledFooter = styled("footer", ({ $theme }) => ({
  paddingTop: $theme.sizing.scale2400,
  paddingBottom: $theme.sizing.scale1000,
}));

export default function Footer() {
  const [css, theme] = useStyletron();

  return (
    <StyledFooter>
      <Grid>
        <Cell span={12}>
          <div
            className={css({
              textAlign: "center",
            })}
          >
            <div>
              <Link href="/terms" passHref>
                <StyledLink>Terms of Service</StyledLink>
              </Link>
              <Link href="/privacy" passHref>
                <StyledLink $style={{ marginLeft: theme.sizing.scale400 }}>
                  Privacy Policy
                </StyledLink>
              </Link>
              <StyledLink
                $style={{
                  cursor: "pointer",
                  marginLeft: theme.sizing.scale400,
                }}
                onClick={() => (window as any).displayPreferenceModal()}
              >
                Manage cookies preferences
              </StyledLink>
            </div>

            <div
              className={css({
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              })}
            >
              <ParagraphSmall $style={{ marginRight: "10px" }}>
                Copyrights {new Date().getFullYear()} &copy;
              </ParagraphSmall>
              <a href="https://mig.team" title="Mig" target="_blank">
                Mig
              </a>
            </div>
          </div>
        </Cell>
      </Grid>
    </StyledFooter>
  );
}
