import React, { useEffect, useState } from "react";
import Link from "next/link";
import {
  HeaderNavigation,
  ALIGN,
  StyledNavigationList,
  StyledNavigationItem,
} from "baseui/header-navigation";
import { StyledLink } from "baseui/link";
import { Button } from "baseui/button";
import { Key, ShoppingCart } from "tabler-icons-react";
import { useStyletron } from "baseui";

import logo from "../assets/logo.svg";

export default function Header() {
  const [css, theme] = useStyletron();
  const [isAtTop, setIsAtTop] = useState(true);

  useEffect(() => {
    function handleScroll() {
      const top =
        (window.pageYOffset || document.documentElement.scrollTop) -
        (document.documentElement.clientTop || 0);

      if (top > 10) {
        setIsAtTop(false);
      } else {
        setIsAtTop(true);
      }
    }

    window.addEventListener("scroll", handleScroll, true);

    return window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <HeaderNavigation
      overrides={{
        Root: {
          style: {
            borderBottom: "none",
            paddingRight: theme.sizing.scale800,
            boxSizing: "border-box",
            position: "fixed",
            width: "100%",
            zIndex: 1,
            left: 0,
            top: 0,
            transition: "all 0.2s ease-in",
            ...(!isAtTop && {
              backgroundColor: theme.colors.background,
              boxShadow: theme.lighting.shadow400,
            }),
          },
        },
      }}
    >
      <StyledNavigationList $align={ALIGN.left}>
        <StyledNavigationItem>
          <Link href="/" passHref>
            <a title="Smart Import">
              <img src={logo} className={css({ height: "38px" })} />
            </a>
          </Link>
        </StyledNavigationItem>
      </StyledNavigationList>
      <StyledNavigationList $align={ALIGN.center} />
      <StyledNavigationList
        $align={ALIGN.right}
        $style={{
          display: "none",
          [theme.mediaQuery.medium]: { display: "flex" },
        }}
      >
        <StyledNavigationItem>
          <Link href="/#features" passHref>
            <StyledLink $style={{ textDecoration: "none" }} href="#basic-link1">
              Features
            </StyledLink>
          </Link>
        </StyledNavigationItem>
        <StyledNavigationItem>
          <Link href="/#pricing" passHref>
            <StyledLink $style={{ textDecoration: "none" }} href="#basic-link1">
              Pricing
            </StyledLink>
          </Link>
        </StyledNavigationItem>
        <StyledNavigationItem>
          <Link href="/#faq" passHref>
            <StyledLink $style={{ textDecoration: "none" }} href="#basic-link1">
              FAQ
            </StyledLink>
          </Link>
        </StyledNavigationItem>
        <StyledNavigationItem>
          <StyledLink
            $style={{ textDecoration: "none" }}
            href="mailto:support@smart-import.app"
          >
            Support
          </StyledLink>
        </StyledNavigationItem>
      </StyledNavigationList>
      <StyledNavigationList $align={ALIGN.right}>
        <StyledNavigationItem>
          <Button
            $as="a"
            kind="secondary"
            href={`${process.env.NEXT_PUBLIC_LICENSE_MANAGER_URL}/`}
            size="compact"
            endEnhancer={<Key />}
          >
            Manage licenses
          </Button>
        </StyledNavigationItem>
        <StyledNavigationItem>
          <Button
            $as="a"
            href={`${process.env.NEXT_PUBLIC_LICENSE_MANAGER_URL}/checkout`}
            size="compact"
            $style={{
              backgroundColor: theme.colors.accent,
            }}
            endEnhancer={<ShoppingCart />}
          >
            Buy now
          </Button>
        </StyledNavigationItem>
      </StyledNavigationList>
    </HeaderNavigation>
  );
}
